import React from "react";
import "../styles/entry.scss";

import SEO from "../components/seo";
import Profiles from "../sections/Profiles";
import TopBar from "../sections/TopBar";
import Bio from "../sections/Bio";
import { createPullstateCore, PullstateProvider } from "pullstate";

const PullstateCore = createPullstateCore();
const instance = PullstateCore.instantiate();

const IndexPage = () => (
  <PullstateProvider instance={instance}>
    <SEO title="Home" />
    <div className="layout">
      <TopBar />
      <Bio />
      <Profiles />
    </div>
  </PullstateProvider>
);

export default IndexPage;
