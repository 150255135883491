import React, { Component } from "react";
import { GithubIcon, LinkedIn } from "./svg/svgIcons";

export default class Profiles extends Component {
  state = {
    showEmail: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showEmail: true });
    });
  }

  render() {
    return (
      <div className="profiles">
        <div className="bio">
          <h2 className="heading">A little more about what I do</h2>
          <div className="profile-buttons">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/lostpebble"
              className="profile-button github"
            >
              <GithubIcon /> lostpebble
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/paul-myburgh-8b064338/"
              className="profile-button linkedin"
            >
              <LinkedIn /> Paul Myburgh
            </a>
          </div>
          <h3 className="email">
            You can also get hold of me at this email: {" "}
            <address>
              {this.state.showEmail
                ? <a href="mailto:paulmyburgh88@gmail.com" target="_blank" rel="noopener noreferrer">
                  paulmyburgh88@gmail.com
                </a>
                : <span>Enable Javascript To See Email</span>}
            </address>
          </h3>
        </div>
      </div>
    );
  }
}
