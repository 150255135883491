import React from "react";

export default function Bio() {
  return (
    <div className="bio">
      <h1 className="heading">Hi there</h1>
      <div className="paragraph">
        <b>I'm Paul</b>, a Full Stack Developer currently working remotely in Paris.
      </div>
      <div className="paragraph">
        My favourite thing about developing is seeking out new and interesting ways to combine technology and the creative arts.
      </div>
      <div className="paragraph">
        I'm the founder of{" "}
        {/* eslint-disable */}
        <a
          target="_blank"
          href="https://www.vibescout.com"
          className="link vibescout"
        >
          <b>Vibescout</b>
        </a>
        , a pet project which has grown up pretty fast and takes up most of my
        time these days.
      </div>
    </div>
  );
}
