import React, { Component, useEffect, useRef, useState } from "react";
import { Motion, spring } from "react-motion";

import photoZipLining from "../images/photos/compressed/photo-zip-lining.jpg";
import photoGiraffe from "../images/photos/compressed/photo-feeding-giraffe.jpg";
import photoBikes from "../images/photos/compressed/photo-riding-bikes-france.jpg";
import photoAsterix from "../images/photos/compressed/photo-asterix.jpg";

import { createAsyncAction, successResult } from "pullstate";

async function loadImageFully(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = src;
  });
}

export const AsyncActionImageLoad = createAsyncAction(async ({ src }) => {
  await loadImageFully(src);
  return successResult();
});

export function MovingImage({ imageSrc, imageAlt, offset = 0 }) {
  const [state, setState] = useState({
    xPos: 3 + offset * 3,
    yPos: 3 + offset * 3,
    scale: 0.85 + offset * 0.10,
    rot: 1 + offset * 1.5,
  });

  const [loadedImage] = AsyncActionImageLoad.useBeckon({ src: imageSrc }, { ssr: false });
  const intervalRef = useRef();

  useEffect(() => {
    intervalRef.current = setInterval(
      () =>
        setState(s => ({
          ...s,
          xPos: Math.random() * 30 - 15,
          yPos: Math.random() * 30 - 15,
          scale: 0.65 + Math.random() * 0.4,
          rot: Math.random() * 20 - 10,
        })),
      Math.round(Math.random() * 200) + 950
    );
    return () => {
      clearInterval(intervalRef.current);
    }
  }, []);

  return (
    <Motion
      defaultStyle={{
        x: state.xPos,
        y: state.yPos,
        s: state.scale,
        r: state.rot,
        o: loadedImage ? 0.85 : 0,
      }}
      style={{
        x: spring(state.xPos, { stiffness: 0.1, damping: 2 }),
        y: spring(state.yPos, { stiffness: 0.1, damping: 2 }),
        s: spring(state.scale, { stiffness: 1, damping: 20 }),
        r: spring(state.rot, { stiffness: 1, damping: 20 }),
        o: spring(loadedImage ? 0.85 : 0),
      }}
    >
      {value => (
        <div className="image-container">
          <img
            style={{
              transform: `translateX(${value.x}%) translateY(${value.y}%) scale(${value.s}) rotateZ(${value.r}deg)`,
              opacity: value.o,
            }}
            id="photo-4"
            src={imageSrc}
            alt={imageAlt}
          />
        </div>
      )}
    </Motion>
  );
}

export default class TopBar extends Component {
  state = {
    xPos: 0,
    yPos: 0,
    scale: 1,
    rot: 0,
  };

  componentDidMount() {
    this.interval = setInterval(
      () =>
        this.setState({
          xPos: Math.random() * 30 - 15,
          yPos: Math.random() * 30 - 15,
          scale: 0.5 + Math.random() * 1.5,
          rot: Math.random() * 20 - 10,
        }),
      1000
    );
  }

  render() {
    return (
      <div className="top-bar">
        <div className="photos">
          <MovingImage
            offset={0}
            imageSrc={photoAsterix}
            imageAlt="Pushing over a boulder in Parc Asterix, France"
          />
          <MovingImage
            offset={-0.5}
            imageSrc={photoGiraffe}
            imageAlt="Feeding a Giraffe close to Stellenbosch"
          />
          <MovingImage
            offset={0.25}
            imageSrc={photoBikes}
            imageAlt="Riding bikes in Arcachon, France"
          />
          <MovingImage
            offset={-0.25}
            imageSrc={photoZipLining}
            imageAlt="Zip-lining just outside Cape Town"
          />
        </div>
      </div>
    );
  }
}
